

















import { Component, Vue } from 'vue-property-decorator'
import Glitch from '../components/effects/Glitch.vue';

@Component({
    components: {
        Glitch
    },
})
export default class SignupSuccessful extends Vue { }
